<template>
    <div v-if="visible" class="isExpiredTipDialog">
        <div class="dialogContent">
            <!-- <img src="~@/assets/img/login/dialogBg.png" alt=""> -->
            <div class="imgs">
                <img :src="isExpiredImg" alt="" />
            </div>
            <div @click="visible = false" class="close"><a-icon type="close-circle" /></div>
            <div class="dialogContentTxt">
                <div class="title">已为您定制专属客服</div>
                <div class="descri">需要继续使用可以联系客服购买哦</div>
                <div class="scan">
                    <img src="~@/assets/img/login/customerServiceCode.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            openFlag: 'Y',
        }
    },
    computed: {
        isExpiredImg() {
            let img1 = require('@/assets/img/login/serviceExpiration.png'),
                img2 = require('@/assets/img/login/trialExpires.png')
            return this.openFlag == 'Y' ? img1 : img2
        },
    },
    methods: {
        open({ openFlag }) {
            this.openFlag = openFlag
            this.visible = true
        },
    },
}
</script>
<style lang="less" scoped>
.isExpiredTipDialog {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    z-index: 3000;
    .dialogContent {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 506px;
        padding-top: 20px;
        .imgs {
            position: absolute;
            top: -38px;
            left: 50%;
            transform: translateX(-50%);
        }
        .close {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 35px;
            color: #fff;
            transform: translate(100%, -70%);
            cursor: pointer;
        }
        .dialogContentTxt {
            background-color: #fff;
            border-radius: 5px;
            text-align: center;
            padding-bottom: 82px;
            padding-top: 58px;
            .title {
                font-size: 28px;
                color: #141414;
            }
            .descri {
                color: #575757;
                margin-bottom: 16px;
                margin-top: 10px;
            }
            .scan {
                width: 266px;
                height: 266px;
                border: 1px solid #131313;
                padding: 9px;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
</style>
